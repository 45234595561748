import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import ProviderLogo4 from "../assets/images/providers/ProviderImg4.jpg";
import ProviderLogo5 from "../assets/images/providers/ProviderImg5.jpg";
import ProviderLogo6 from "../assets/images/providers/ProviderImg6.jpg";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import { Col, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import Casino1 from "../assets/images/games-icon/casinoezugi-icon.png";
import SportsbookIcon from "../assets/images/icons/exchange-icon.png";
import Evolution from "../assets/images/games-icon/Ezugi.png";
import Aviator from "../assets/images/games-icon/Aviatornew.png";
import HomeIconnew from "../assets/images/games-icon/home.png";
import DepositIcon from "../assets/images/games-icon/Depositnew.png";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState("");

  return (
    <footer className="footer">
      <div className="providers_sec">
        <Row>
          <Col md={6} xs={12}>
            <div className="provider-mob-border">
              <div className="heading mb-4">
                <div className="text">
                  <h5>Top Providers</h5>
                  <p>
                    Play Your favorite Casino Games with{" "}
                    <span>Top Providers</span>
                  </p>
                </div>
              </div>

              <div className="providers_logo d-flex flex-wrap justify-content-center">
                <a href="#" className="logo_box">
                  <img src={ProviderLogo1} alt="Provider Logo" />
                </a>
                {/* <a href="#" className="logo_box">
                  <img src={ProviderLogo2} alt="Provider Logo" />
                </a> */}
                <a href="#" className="logo_box">
                  <img src={ProviderLogo3} alt="Provider Logo" />
                </a>
                <a href="#" className="logo_box">
                  <img src={ProviderLogo4} alt="Provider Logo" />
                </a>
                {/* <a href="#" className="logo_box">
                  <img src={ProviderLogo5} alt="Provider Logo" />
                </a> */}
                <a href="#" className="logo_box">
                  <img src={ProviderLogo6} alt="Provider Logo" />
                </a>
                {/* <a href="#" className="logo_box">
                  <img src={ProviderLogo7} alt="Provider Logo" />
                </a> */}
              </div>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="">
              <div className="heading payment-heading mb-4 ">
                <div className="text">
                  <h5>Payment Gateway</h5>
                  <p>
                    Accept Process &amp; disburse <span>Digital Payments </span>
                    for your bussiness
                  </p>
                </div>
              </div>

              <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                <a href="#" className="logo_box">
                  <img src={PaymentLogo1} alt="Provider Logo" />
                </a>
                <a href="#" className="logo_box payment-height">
                  <img src={PaymentLogo2} alt="Provider Logo" />
                </a>
                <a href="#" className="logo_box">
                  <img src={PaymentLogo3} alt="Provider Logo" />
                </a>
                <a href="#" className="logo_box">
                  <img src={PaymentLogo4} alt="Provider Logo" />
                </a>
                <a href="#" className="logo_box payment-height">
                  <img src={PaymentLogo5} alt="Provider Logo" />
                </a>
                <a href="#" className="logo_box">
                  <img src={PaymentLogo6} alt="Provider Logo" />
                </a>
                <a href="#" className="logo_box payment-height">
                  <img src={PaymentLogo7} alt="Provider Logo" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="container">
        <div className="providers_img">
          <a href="#">
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="#">
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="#">
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="#">
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="footer-links">
          <ul>
            <li>
              <a href="/contactuss">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicys ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegames">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplays">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrules">Games Rules</a>
            </li>
            <li>
              <a href="/termss">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div className="helpline d-none">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                : "#"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            WhatsApp Us{" "}
          </a>
        </div>

        <div className="d-flex justify-content-center mt-2"></div>
        {/* <div className="d-flex justify-content-center mt-2">
        <img
          src={PaymentMod}
          loading="lazy"
          style={{ width: "250px" }}
          alt="Payment Otions"
        />
      </div> */}

        <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
          <img
            src={Betfair}
            loading="lazy"
            className="mb-3 mx-3 betfair"
            alt="Betfair Logo"
          />
          <a href="#">
            <img
              src={Gamble18}
              loading="lazy"
              alt="Gamble Responsible"
              className="mb-3 betfair"
            />
          </a>
        </div>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? appDetails?.WHATS_APP_URL
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "betby" ? "active" : ""}
                href="/betby"
              >
                <img src={SportsbookIcon} />
                <span>Sportsbook</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "casino/ezugi" ? "active" : ""}
                href={CasinoProviders["ezugi"]?.href}
              >
                <img src={Casino1} />
                <span>Ezugi</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "casino/evolution" ? "active" : ""}
                href={CasinoProviders["evolution"]?.href}
              >
                <img src={Evolution} />
                <span>Evolution</span>
              </a>
            </li>
            <li className="home">
              <a className="active" href="/home">
                <img src={HomeIconnew} />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="/casino/spribe/aviator">
                <img src={Aviator} />
                <span>Aviator</span>
              </a>
            </li>
            {/* <li>
              <a href="/gatewaylist">
                <img src={DepositIcon} />
                <span>Deposit</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
