import React, { Suspense, useEffect } from "react";
import "./App.scss";
import Footer from "./containers/Footer";
import BottomNav from "./containers/BottomNav";
// import  from "./containers/privacyPolicy";

import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import ProfitAndLoss from "./views/pages/privatePages/profit&loss/ProfitAndLoss";
import HeaderAfterLogin from "./containers/HeaderAfterLogin";
import ContactUsBefore from "./containers/ContactUsBefore";
import TestLandingPage from "./views/pages/publicPages/landingPage/testLandingPage";
import LoaderImg from "./assets/images/loader.gif";
import ContactUs from "./views/pages/privatePages/FooterPages/ContactUs";
import PrivacyPolicy from "./views/pages/privatePages/FooterPages/PrivacyPolicy";
import ResponsibleGame from "./views/pages/privatePages/FooterPages/ResponsibleGame";
import FairPlay from "./views/pages/privatePages/FooterPages/FairPlay";
import GamesRule from "./views/pages/privatePages/FooterPages/GamesRule";
import Terms from "./views/pages/privatePages/FooterPages/Terms";
// import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import BeforeContactUs from "./views/pages/publicPages/FooterPages/BeforeContactUs";
import BeforeFairPlay from "./views/pages/publicPages/FooterPages/BeforeFairPlay";
import BeforeGamesRule from "./views/pages/publicPages/FooterPages/BeforeGamesRule";
import BeforePrivacyPolicy from "./views/pages/publicPages/FooterPages/BeforePrivacyPolicy";
import BeforeResponsibleGame from "./views/pages/publicPages/FooterPages/BeforeResponsibleGame";
import BeforeTerms from "./views/pages/publicPages/FooterPages/BeforeTerms";
import Slots from "./views/pages/privatePages/Slots";
import DepositGateway from "./views/pages/privatePages/deposit/depositGateway";
// import GatewaysList from "./views/pages/privatePages/deposit/gatewaysList";
import GatewaysList from "./views/pages/privatePages/deposit/gatewaysList";
import BonusCard from "./views/pages/privatePages/bonus/bonusCard";
import Bonus from "./views/pages/privatePages/bonus";
import Referral from "./views/pages/privatePages/Referral";
import Betby from "./views/pages/privatePages/betby";
import ExchangeSPMSportsLobbyPage from "./views/pages/privatePages/exchange/ExchangeSPMSportsLobbyPage";
// import Termsandcondition from "./containers/Termsandcondition";
import { APP_CONST } from "./config/const";
// import Fairplay from "./containers/Fairplay";
const Maintenance = React.lazy(() =>
  import("./views/pages/publicPages/Maintenance")
);
const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("./views/pages/publicPages/landingPage")
);
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
// const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const Support = React.lazy(() => import("./views/pages/privatePages/support"));
const Contact = React.lazy(() => import("./views/pages/privatePages/contact"));
// const PrivacyPolicy = React.lazy(() => import("./containers/privacyPolicy"));
const ResponsibleGaming = React.lazy(() =>
  import("./containers/ResponsibleGaming")
);
const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
const Fairplay = React.lazy(() => import("./containers/Fairplay"));
const GameRules = React.lazy(() => import("./containers/GameRules"));
// import GameRules from "./containers/GameRules";

const Termsandcondition = React.lazy(() =>
  import("./containers/Termsandcondition")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails, organisationStatus } = useSelector((state) => state.app);
  //const maxToast = 1;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppDetails());
  }, []);

  // function Image() {
  //   setTimeout(() => {
  //     return ;
  //   }, 5000);
  // }

  const setVhUnit = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  useEffect(() => {
    setVhUnit();
    window.addEventListener("resize", setVhUnit);
    return () => {
      window.removeEventListener("resize", setVhUnit);
    };
  }, []);
  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;
    if (
      organisationStatus !== null &&
      organisationStatus !== APP_CONST.ORGANISATION_STATUS.Live &&
      window.location.pathname.indexOf("maintenance") < 0
    ) {
      window.location.href = "/maintenance";
    }
  }, [appDetails]);
  return (
    <Router>
      <Suspense
        fallback={
          <div className="loaderSec">
            <img src={LoaderImg} />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
                <BeforeLoginFooter />
                <BottomNav />
              </PublicRoutes>
            }
          />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/bonus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bonus />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus/:id"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BonusCard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            // path="/sports/exchange"
            path="/sports/:gameId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/spmsports/:category"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeSPMSportsLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/support"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Support />

                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Contact />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacyPolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PrivacyPolicy />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Slots"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Slots />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contactus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ContactUs />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PrivacyPolicy />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/responsiblegame"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ResponsibleGame />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FairPlay />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gamesrule"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GamesRule />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/terms"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Terms />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/contactuss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeContactUs />
              </PublicRoutes>
            }
          />
          <Route
            path="/privacypolicys"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforePrivacyPolicy />
              </PublicRoutes>
            }
          />
          <Route
            path="/fairplays"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeFairPlay />
              </PublicRoutes>
            }
          />

          <Route
            path="/responsiblegames"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeResponsibleGame />
              </PublicRoutes>
            }
          />
          <Route
            path="/gamesrules"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeGamesRule />
              </PublicRoutes>
            }
          />
          <Route
            path="/termss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeTerms />
              </PublicRoutes>
            }
          />

          <Route
            path="/profit&loss"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ProfitAndLoss />
                <BottomNav />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MatchDetailPage />
                <BottomNav />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/home2"
            element={
              <PublicRoutes isAuth={isAuth}>
                <TestLandingPage />
              </PublicRoutes>
            }
          />
          <Route
            path="/betby"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <HeaderAfterLogin />
                <Betby />
                {/* <Footer /> */}
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Suspense>
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
        // limit={4}
      />
    </Router>
  );
};

export default App;
